<template>
    <div>
        <div class="content-header container-fluid bg-white">
            <div class="row">
                <div class="col-sm-4">
                    <h4>
                        <i class="ti-skype" /> 访问 | 访问记录
                    </h4>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <el-button type="primary" @click="create()">新增记录</el-button>
                    <button type="submit" class="el-button el-button--success" @click="exportExcel">
                        <span>导出Excel</span>
                    </button>
                    <el-button @click="QRCode()">二维码</el-button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="w-block" style="padding: 2px;">
                        <el-date-picker v-model="grid.sea.Days" type="daterange" @change="filter" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>

                        <el-input v-model="grid.sea.SureName" placeholder="请输入确认人姓名查询"
                            style="width: 300px;margin-left: 5px">
                            <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                        </el-input>
                        <el-input v-model="grid.sea.Visitor_Name" placeholder="请输入被访问人员查询"
                            style="width: 300px;margin-left: 5px">
                            <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                        </el-input>
                    </div>
                    <wGrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                        :page-index="grid.sea.PAGE_INDEX + 1" @page-changed="loadPage">
                        <el-table-column type="index" label="序号" width="50" align="center" />
                        <el-table-column prop="TITLE" label="日期" width="100" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.CREATED_DTStr | datetime('YYYY-MM-DD') }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="Visitor_Name" label="姓名" width="100" align="center" />
                        <el-table-column prop="Visitor_Sex" label="性别" width="50" align="center" />
                        <el-table-column prop="Visitor_Id_Numer" label="身份证号" width="180" align="center">
                        </el-table-column>
                        <el-table-column label="联系号码" width="150" prop="Visitor_Mobile" align="center">
                        </el-table-column>

                        <el-table-column prop="Visitor_Work_Place" label="工作单位" width="200" align="center" />
                        <el-table-column prop="Be_Visited_Name" label="被访问人" width="80" align="center" />
                        <el-table-column prop="Reason" label="来访原因" width="150" align="center" />

                        <el-table-column label="来访时间" width="100" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.CREATED_DTStr }}<!-- | datetime('YYYY-MM-DD')-->
                            </template>
                        </el-table-column>

                        <el-table-column label="确认时间" width="180" align="center">
                            <template slot-scope="scope">
                                <div v-if="scope.row.Sure_Time != '0001-01-01T00:00:00'">
                                    {{ scope.row.Sure_Time | datetime('YYYY-MM-DD HH:mm') }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Sure_Name" label="确认人" width="80" />

                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" @click="edit(scope.row)">编辑</el-button>
                                <el-button type="text" @click="del(scope.row.ID)">删除</el-button>
                            </template>
                        </el-table-column>
                    </wGrid>
                </div>
            </div>
        </div>
        <Edit ref="edit" @on-saved="filter"></Edit>
        <QrCode ref="QrCode"></QrCode>
    </div>
</template>

<script>
import wGrid from "@/components/wgrid";
import jsFileDownload from 'js-file-download'
import Edit from './edit'
import QrCode from './QRCode'
export default {
    components: { wGrid, Edit, QrCode },
    data() {
        return {
            grid: {
                sea: {
                    PAGE_SIZE: 12,
                    PAGE_INDEX: 0,
                    Days: [],
                    SureName: '',
                    Visitor_Name: ''
                },
                ls: [],
                total: 0,
                loading: false
            },
        }
    },
    mounted() {
        
        this.filter();
    },
    methods: {
        loadPage() { },
        filter() {
            this.getList();
        },
        getList() {
            let self = this;
            let data = {
                Dts: this.grid.sea.Days[0] || '',
                Dte: this.grid.sea.Days[1] || '',
                SureName: self.grid.sea.SureName,
                Visitor_Name: self.grid.sea.Visitor_Name,
                PAGE_SIZE:this.grid.sea.PAGE_SIZE,
                PAGE_INDEX: this.grid.sea.PAGE_INDEX,
            }
            this.grid.loading = true
            this.whale.remote.getCollection({
                url: '/api/School/VIS/VisRegister/GetPageList',
                data: data,
                completed(its) {
                    console.log('gg', its)
                    self.grid.loading = false
                    self.grid.ls = its
                }
            })
        },
        create() {
            this.$refs.edit.init()
        },
        edit(r) {
            this.$refs.edit.init(r)
        },
        QRCode() {
            this.$refs.QrCode.QRCode()
        },
        exportExcel() {
            let data = {
                Dts: '',
                Dte: '',
                SureName: this.grid.SureName,
                Visitor_Name: this.grid.Visitor_Name
            }
            this.whale.remote.getExportResult({
                url: "/api/School/VIS/VisRegister/Export",
                data: data,
                completed: function (its) {
                    jsFileDownload(its, '导出访问记录.xls')
                }
            })
        },
        del(g) {
            let self = this;
            this.$confirm("真的要撤消这条记录吗？", "撤消", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                self.whale.remote.getResult({
                    url: '/api/School/VIS/VisRegister/Delete?ID=' + g,
                    completed() {
                        self.filter();
                    }
                })
            }).catch(() => { })
        },
    },
}
</script>
<style lang="less" scoped></style>